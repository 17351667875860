const LinkedinIcon = ({width, height}) => {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height} 
        viewBox="0 0 24 24"
        fill="currentColor" 
        id="linkedin">
        <path d="M5.5 8.014h-5a.5.5 0 0 0-.5.5v15a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-15a.5.5 0 0 0-.5-.5zM3.206-.015C1.438-.015 0 1.442 0 3.231c0 1.791 1.438 3.248 3.206 3.248s3.206-1.457 3.206-3.248c0-1.79-1.438-3.246-3.206-3.246zM18 7.909c-2.438 0-4.098.602-5 1.051v-.445a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5v15a.5.5 0 0 0 .5.5h4c.275 0 .5-.225.5-.5v-8.238c0-1.285 1.32-2.246 2.5-2.246s2.5.961 2.5 2.246v8.238c0 .275.225.5.5.5h5c.275 0 .5-.225.5-.5v-9.611a6.004 6.004 0 0 0-6-5.995z"></path></svg>
       
    )
}

export default LinkedinIcon;