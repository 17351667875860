const MoneyIcon = ({width, height}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_443_3628)">
        <rect x="2" y="6" width="20" height="12" stroke="#00f0b5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22 10C21.4747 10 20.9546 9.89654 20.4693 9.69552C19.984 9.4945 19.543 9.19986 19.1716 8.82843C18.8001 8.45699 18.5055 8.01604 18.3045 7.53073C18.1035 7.04543 18 6.52529 18 6L22 6L22 10Z" stroke="#00f0b5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18 18C18 16.9391 18.4214 15.9217 19.1716 15.1716C19.9217 14.4214 20.9391 14 22 14L22 18L18 18Z" stroke="#00f0b5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2 14C3.06087 14 4.07828 14.4214 4.82843 15.1716C5.57857 15.9217 6 16.9391 6 18L2 18L2 14Z" stroke="#00f0b5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 6C6 7.06087 5.57857 8.07828 4.82843 8.82843C4.07828 9.57857 3.06087 10 2 10L2 6H6Z" stroke="#00f0b5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.0741 9.5H11.3333C10.597 9.5 10 10.0596 10 10.75C10 11.4404 10.597 12 11.3333 12H13.1111C13.8475 12 14.4444 12.5596 14.4444 13.25C14.4444 13.9404 13.8475 14.5 13.1111 14.5H10" stroke="#00f0b5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 9.51733V8.5" stroke="#00f0b5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 15.5173V14.5" stroke="#00f0b5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_443_3628">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    );
}

export default MoneyIcon;